import React from 'react';
import LangProvider from '../context/LangContext';
import { Helmet } from 'react-helmet';

const PageWrapper = ({ children, lang, translations, noMetas }) => {
  return (
    <LangProvider lang={lang} translations={translations}>
      {noMetas ? null : (
        <Helmet>
          <title>{translations.metas['metaTitle']}</title>
          <meta property="og:title" content={translations.metas['metaTitle']} />
          <meta
            property="og:description"
            content={translations.metas['ogDescription']}
          />
          <meta
            name="description"
            content={translations.metas['metaDescription']}
          />
          <meta property="og:image" content={translations.metas['ogImage']} />
          <meta
            property="og:image:width"
            content={translations.metas['ogImageWidth']}
          />
          <meta
            property="og:image:height"
            content={translations.metas['ogImageHeight']}
          />
          <meta property="og:url" content={translations.metas['ogUrl']} />
          <meta
            name="twitter:title"
            content={translations.metas['twitterTitle']}
          />
          <meta
            name="twitter:description"
            content={translations.metas['twitterDescription']}
          />
          <meta
            name="twitter:image"
            content={
              translations.metas['twitterImage'] ||
              'https://eddisrupt.com/images/zoomcall3.png'
            }
          />
          <meta
            name="twitter:card"
            content={translations.metas['twitterCard']}
          />
          <meta
            name="facebook-domain-verification"
            content="orsuexk7mlo1xthxkpofflm8dwsyq1"
          />
        </Helmet>
      )}
      {children}
    </LangProvider>
  );
};

export default PageWrapper;
